module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8MVYBDGVHP"],"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZhivaAI Documentation","short_name":"Docs | ZhivaAI","description":"Documentation for ZhivaAI","lang":"en","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d3bce0fa607f90c24a2b9aa477be155"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/github/workspace","commonmark":false},
    },{
      plugin: require('../plugins/gatsby-remark-mermaid-parser/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"backgroundColor":"transparent","quality":70,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-transformer-openapi/gatsby-browser.js'),
      options: {"plugins":[],"instanceName":"openapi","docfiles":[{"path":"./openapi/v0.1.yaml","out":"./content/openapi/v0.1.yaml","name":"V0.1"},{"path":"./openapi/v0.2.yaml","out":"./content/openapi/v0.2.yaml","name":"V0.2"},{"path":"./openapi/v0.3.yaml","out":"./content/openapi/v0.3.yaml","name":"V0.3"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
